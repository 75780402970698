import styled from "styled-components"

import { IconButton } from "@minuthq/meatball-ui-react"

import { AddButton } from "src/ui/Button/AddButton"
import TrashSvg from "src/ui/icons/trash.svg"
import {
  MTextField,
  TMTextFieldAcceptedTypes,
} from "src/ui/MTextField/MTextField"
import { spacing } from "src/ui/spacing"

export function MultipleValuesField({
  textFields,
  onChange,
  required,
  inputType = "text",
  buttonLabel,
}: {
  textFields: string[]
  required?: boolean
  onChange: (textFields: string[]) => void
  buttonLabel: string
  inputType?: TMTextFieldAcceptedTypes
}) {
  const isRequired = textFields.length !== 0 && required

  const _textFields = isRequired
    ? [textFields[0] ?? "", ...textFields.slice(1)]
    : [...textFields]

  return (
    <Wrapper>
      {_textFields.map((field, index) => (
        <MTextField
          key={`textfield-${index}`}
          value={field}
          required={required || isRequired}
          onChange={(value) => {
            const updatedTextFields = [..._textFields]
            updatedTextFields[index] = value

            onChange(updatedTextFields)
          }}
          type={inputType}
          endAdornment={
            isRequired && _textFields.length === 1 ? undefined : (
              <IconButton
                size="small"
                variant="minimal"
                onClick={() => {
                  const updatedTextFields = _textFields.filter(
                    (_, i) => index !== i
                  )

                  onChange(updatedTextFields)
                }}
                Icon={TrashSvg}
              />
            )
          }
        />
      ))}
      <div>
        <AddButton
          variant="minimal"
          onClick={() => {
            const updatedTextFields = [..._textFields, ""]

            onChange(updatedTextFields)
          }}
        >
          {buttonLabel}
        </AddButton>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: grid;
  gap: ${spacing.M};
`
